<template>
<div class="card" v-show="hmm" v-if="data.maintainer">
    <h5>{{ data.maintainer }}</h5>
    <div class="paneldata">
        <div class="col-md-4">
            <img :src="data.image" class="img-fluid rounded" :alt="data.maintainer">
        </div>
        <div class="panel">
            <p>Devices maintained : <label v-show="data.rmx1971">Realme 5 Pro(RMX1971)</label> <label v-show="data.r5x">Realme 5 Series(r5x)</label></p>
            <p>Roms Maintained : {{ data.roms }}</p>
            <p>Github :  <a :href="data.github" style="color:white">{{ data.maintainer }}</a></p>
            <p v-show="data.telegram">Telegram Id : {{ data.telegram }}</p>
            <p style="white-space: pre-line;" v-show="data.content">Info about maintainer : <br>{{ data.content }}</p>
        </div>
    </div>
</div>
  <div class="notfound" v-else>
    <img src="../assets/404.png" alt="404(Not Found)">
    <h1>404</h1>
    <p>The page you are looking for is not available</p>
  </div>
</template>
<script>
import axios from 'axios';

  export default {
    name: 'Maintainers',
    components: {
    },
    data: () => ({
        length: '',
        data: [],
        hmm : false,
    }),

    beforeMount () {
        this.getmaintainersdata();
    },

    methods: {
        getmaintainersdata() {
            let maintainer = `?maintainer=${this.$route.params.name}`
            let config = {
                method : 'get',
                url : `https://kharame-devices.herokuapp.com/getMaintainersData${maintainer}`,
            };
            axios(config)
            .then((response) => {
                if (response && response.data.message && response.data.status === 'S'){
                    this.length = response.data.message.length
                    this.data = response.data.message
                    this.data = this.data[0]
                    this.hmm = true
                } else {
                    this.hmm = false
                    this.haa = true
                }

            })
        },
    },
  }
</script>
<style scoped>
.card {
    background: #467cc1;
    color: white;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    padding: 2%;
}
.paneldata {
    display: flex;
}
.panel {
    margin-left: 2.5%;
}
.notfound {
    text-align: center;
	margin-top: 7%;
    color: white;
}
@media (max-width: 550px) {
    .paneldata {
        display: block;
    }
    .panel {
        margin-top: 10%;
    }
}
</style>